import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store';
import {
  ROLE_USER
} from '#/constants/user-roles.type';
import { USER_FETCH_PROFILE, CLIENT_FETCH_ROLE } from '@/store/user.module';
import { APP_FETCH_THEME } from '@/store/actions.type';
import { APP_SET_ORIGIN } from '@/store/mutations.type';
import { COMMUNITY_FETCH_MEMBERS_IDS } from '@/store/community/members.module'
import { COMMUNITY_FETCH_INFO, COMMUNITY_FETCH_STATUS } from '@/store/community/info.module';
import { NOTIFICATIONS_FETCH_STATE } from '@/store/notifications/notifications.module';
import { MISC_FETCH_PROVINCES, MISC_FETCH_COUNTRY_CODE } from '@/store/misc/provinces.module';
import { COMMUNITY_FETCH_TAGS } from '@/store/community/tags.module';
import { COMMUNITY_FETCH_CHAT_UNSEEN } from '@/store/community/chat.module';
import { RELOAD_NOTIF_INTERVAL } from '#/constants/constants.type';
import { loadCitiServiPixel } from '~/assets/scripts/citiservipixel';
import { COMMUNITY_FETCH_HAS_RAFFLE_DATA } from '@/store/community/redeem.module';
const ifNotAuthenticated = async (to: any, from: any, next: any) => {

  const urlParams = new URLSearchParams(window.location.search)
  if(urlParams.get('clientAuthToken')){
    await store.commit('USER_SET_AUTH_DATA',{
      authToken:urlParams.get('clientAuthToken'),
      fromSuite: urlParams.get('fromSuite')
    })
    await store.dispatch( CLIENT_FETCH_ROLE );
    await store.dispatch( USER_FETCH_PROFILE );
    return next({ name: 'activities', params: { origin: to.params.origin } });
  }
  else if(urlParams.get('testerAuthToken')){
    await store.commit('USER_SET_AUTH', {
      authToken: urlParams.get('testerAuthToken'),
      role: ROLE_USER,
      nextStep: 'welcome'
    });
    await store.dispatch( USER_FETCH_PROFILE );
    return next({ name: 'activities', params: { origin: to.params.origin } });
  }
  const { nickname, id } = store.getters.userProfile;
  if(to.name == 'conditions' || to.name == 'privacy_policy') return next();
  if(to.name != '2FA' && store.getters.needs2FA){
    return next({name:"2FA", params: { origin: to.params.origin, }})
  }
  else if(to.name == "2FA" && store.getters.needs2FA){
    return next()
  }
  if(!store.getters.isAuthenticated) {
    return next();
  }

  else if ( !store.getters.isClient && to.name !== 'completeProfile' && (!nickname || nickname.includes('-'+id)) ){
    return next({ name: 'activities', params: { origin: to.params.origin } });
  }

  return next();
};
const suiteInitialServices = async () => {
  store.dispatch('getCreditsPlan')
  store.dispatch('getNotifications')
}

const initialServices = async (to: any) => {
  await store.dispatch( MISC_FETCH_COUNTRY_CODE );
  await store.dispatch( MISC_FETCH_PROVINCES );
  await store.dispatch( APP_FETCH_THEME, { origin: to.params.origin } )
  await store.dispatch( USER_FETCH_PROFILE );
  await store.dispatch( COMMUNITY_FETCH_INFO );
  setInterval( (  ) => {
    store.dispatch(NOTIFICATIONS_FETCH_STATE, { isClient: store.getters.isClient, hideSpinner: false } );
  }, RELOAD_NOTIF_INTERVAL );
  if(store.getters.isClient){
    suiteInitialServices()
    await store.dispatch(COMMUNITY_FETCH_MEMBERS_IDS);
    await store.dispatch(COMMUNITY_FETCH_STATUS);
    await store.dispatch(COMMUNITY_FETCH_TAGS);
    await store.dispatch(COMMUNITY_FETCH_HAS_RAFFLE_DATA);
    if(!store.getters.isObservator) store.dispatch(COMMUNITY_FETCH_CHAT_UNSEEN)
  }
  if(store.getters.isUser){
    loadCitiServiPixel(store.getters.userProfile.email)
  }
}

const ifAuthenticated = async (to: any, from: any, next: any) => {
  if ( store.getters.isAuthenticated ) {
    if(to.name != '2FA' && store.getters.needs2FA){
      return next({name:"2FA", params: { origin: to.params.origin, }})
    }
    else if(to.name == "2FA" && store.getters.needs2FA){
      return next()
    }
    await initialServices(to)
    const { nickname, id } = store.getters.userProfile;
    if ( store.getters.nextStep == "afterLogin" && !store.getters.isClient && to.name !== 'completeProfile' && (!nickname || nickname.includes('-'+id)) ){
      return next({ name: 'completeProfile', params: { origin: to.params.origin, }, });
    }
    return next();
  }
  if (store.getters.isClient) next( { name: 'clientLogin', params: { origin: to.params.origin, }, } )
  return next({ name: 'login', params: { origin: to.params.origin, redirectTo: to} });
};

const usersOnly = (to: any, from: any, next: any) => {
  if(to.name == "profile" && !store.getters.isUser) return next( { name: 'profileClient', params: { origin: to.params.origin } } )
  return store.getters.isUser
    ? next( )
    : next( { name: 'activities', params: { origin: to.params.origin } } );
}

const moderatorsOnly = ( to: any, from: any, next: any ) => {
  if(to.name == "chat" && !store.getters.isModerator) return next( { name: 'chatMember', params: { origin: to.params.origin } } )
  return store.getters.isModerator
    ? next( )
    : next( { name: 'activities', params: { origin: to.params.origin } } );
}

const clientsOnly = (to: any, from: any, next: any) => {
  if(to.name == "chat" && !store.getters.isClient) return next( { name: 'chatMember', params: { origin: to.params.origin } } )
  if(to.name == "profileClient" && !store.getters.isClient) return next( { name: 'profile', params: { origin: to.params.origin } } )
  return store.getters.isClient
    ? next( )
    : next( { name: 'activities', params: { origin: to.params.origin } } );
}

const routes = [
  { path: '/', name:'mainPage', component: () => import( '#/views/MainPage.vue' ), },
  { path: '/c/:origin/videochat-test', name: 'videochat-test', component: () => import( '@/views/VideoChatTestPage.vue' ), props:true },
  { path: '/c/:origin/videochat-exit', name: 'videochat-exit', component: () => import( '@/views/VideoChatExitPage.vue' ), props:true },
  { path: '/c/:origin/activities/:id/video-chat-public', name: 'videochat-public', component: () => import( '@/views/VideoChatPublicPage.vue' ), props:{ isObserverRoute: false } },
  { path: '/c/:origin/activities/:id/video-chat-public-observer', name: 'videochat-public-observer', component: () => import( '@/views/VideoChatPublicPage.vue' ), props:{ isObserverRoute: true } },
  { path: '/c/:origin', name:'AuthView', component: () => import( '@/views/AuthView.vue' ), beforeEnter:  ifNotAuthenticated,
    children: [
      {
        path:         'login',
        name:         'login',
        component: () => import( '@/views/LoginPage.vue' )   ,
      },
      { 
        path: 'signup/:hash', 
        name:'signup', 
        component: () => import( '@/views/SignUpPage.vue' ) },
      {
        path:         'backoffice',
        name:         'clientLogin',
        component: () => import( '@/views/ClientLoginPage.vue' )   ,
      },
      {
        path:         'complete-profile',
        name:         'completeProfile',
        component: () => import( '@/views/CompleteProfilePage.vue' )   ,
      },
      {
        path:         '2FA',
        name:         '2FA',
        component: () => import( '@/views/2FAPage.vue' )   ,
      },
      {
        path:         'reset-password',
        name:         'resetPassword',
        component: () => import( '@/views/ResetPasswordPage.vue' )   ,
      },
      {
        path:         'reset-password-backoffice',
        name:         'resetPasswordBackoffice',
        component: () => import( '@/views/ResetPasswordClientPage.vue' )   ,
      },
      {
        path: 'conditions',
        name: 'conditions',
        component: () => import( '@/views/ConditionsPage.vue' ),
      },
      {
        path: 'privacy_policy',
        name: 'privacy_policy',
        component: () => import( '@/views/PrivacyPolicyPage.vue' ),
      },
    ]
  },
  { path: '/c/:origin', component: () => import( '@/views/PanelView.vue' ), beforeEnter: ifAuthenticated,
    children: [
      { path: 'video-backend', name:'videoBackend', component: () => import( '@/views/VideoBackend.vue' ), },
      { path: 'image-downloader', name:'imageDownloader', component: () => import( '@/views/ImageDownloadPage.vue' ), },
      { path: 'welcome', name: 'welcome', component: () => import( '@/views/WelcomePage.vue' ), },
      { path: 'members', name: 'members', component: () => import( '@/views/MembersPage.vue' ) },
      { path: 'members/preview', name: 'members.preview', component: () => import( '@/views/MembersLoadPage.vue' ), beforeEnter: moderatorsOnly},
      { path: 'redeem', name: 'redeem', component: () => import( '@/views/RedeemPage.vue' )},
      { path: 'redeemReport', name: 'redeemReport', component: () => import( '@/views/RedeemReportPage.vue' )},
      { path: 'canjeo-bankia', name: 'redeemStatic', component: () => import( '@/views/RedeemPageStatic.vue' ) },
      { path: 'chat', name: 'chat', component: () => import( '@/views/ChatPage.vue' ), beforeEnter: clientsOnly},
      { path: 'chat', name: 'chatMember', component: () => import( '@/views/ChatPageMember.vue' )},
      { path: 'activities/step1', name: 'activities.add', component: () => import( '@/views/AddActivityPage.vue' ), beforeEnter: moderatorsOnly,  props:{STEP:1, stepType:"NEW"}},
      { path: 'activities/:id/step1', name: 'activities.edit', component: () => import( '@/views/AddActivityPage.vue' ), beforeEnter: moderatorsOnly,  props:{STEP:1,stepType:"EDIT"}},
      { path: 'activities/:id/step2', name: 'activities.step2', component: () => import( '@/views/AddActivityPage.vue' ), beforeEnter: moderatorsOnly, props:{STEP:2,stepType:"EDIT"}},
      { path: 'activities/:id/step3', name: 'activities.step3', component: () => import( '@/views/AddActivityPage.vue' ), beforeEnter: moderatorsOnly, props:{STEP:3,stepType:"EDIT"}},
      { path: 'activities', name: 'activities', component: () => import( '@/views/ActivitiesPage.vue' ),
        children: [
          { path: ':id', component: () => import( '@/views/ActivityView.vue' ),
            children: [
              { path: 'info', name: 'activity.info', component: () => import( '@/views/ActivityInfoPage.vue' ), props:true },
              { path: 'survey', name: 'activities.survey', component: () => import( '@/views/SurveyExecution.vue' )},
              { path: 'heatmap', name: 'activities.heatmap', component: () => import( '@/views/HeatMapExecution.vue' ) },
              { path: 'video-chat', name: 'activities.videochat', component: () => import( '@/views/VideoChatPage.vue' ), props:true},
              { path: 'video-chat-rest', name: 'activities.videochatrest', component: () => import( '@/views/VideochatRestyling.vue' ), props:true},
              { path: 'wat-ux', name: 'activities.watux', component: () => import( '@/views/WatUxPage.vue' ), props:true},
              { path: 'members', name: 'activity.members', component: () => import( '@/views/ActivityMembersPage.vue' ), },
              { path: 'report', name: 'activity.report', component: () => import( '@/views/ActivityReportPage.vue' ), },
            ]
          },
        ]
      },
      { path: 'notifications', name: 'notifications', component: () => import( '@/views/NotificationsPage.vue' ), },
      { path: 'profile', name: 'profile', component: () => import( '@/views/ProfilePage.vue' ), beforeEnter: usersOnly},
      { path: 'profile', name: 'profileClient', component: () => import( '@/views/ProfileClientNew.vue' ), beforeEnter: clientsOnly},
      { path: 'historial', name: 'historical', component: () => import( '@/views/HistoricalPage.vue' ), },
      { path: 'search', name:'search', component: () => import( '@/views/SearchPage.vue' )},
      { path: 'email', name:'email', component: () => import( '@/views/EmailCustomPage.vue' )},
      { path: 'gestion-labs', name:'customLabs', component: () => import( '@/views/CustomLabsPage.vue' )},
      { path: 'moderator-admin', name:'moderator-admin', component: () => import( '@/views/ModeratorAdminPage.vue' )}
    ],
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.onError(error => {
  const chunkError = error.message.match(/Loading CSS chunk (.*?) failed/i);
  if (chunkError) {
    const oldChunkId = localStorage.getItem('chunkId');
    const newChunkId = chunkError[1];
    if (!oldChunkId || oldChunkId !== newChunkId) {
      localStorage.setItem('chunkId', newChunkId);
      window.location.reload();
    }
  }
});

router.beforeEach( async ( to, from, next ) => {
  if ( !store.getters.origin ) store.commit( APP_SET_ORIGIN, to.params.origin );
  next( );
} );

export default router;
